'use strict';

(function() {

  class MainController {

    constructor(Auth, usSpinnerService, Organization) {
      this.usSpinnerService = usSpinnerService;
      this.isOrganizationUserPlus = Auth.isOrganizationUserPlus;
      this.isAdmin = Auth.isAdmin;
      this.isLoggedIn = Auth.isLoggedIn;
      this.popups = false;
    }

    $onInit() {
    }

    startSpin(spinner) {
      this.usSpinnerService.spin(spinner);
    }

    stopSpin(spinner) {
      this.usSpinnerService.stop(spinner);
    }

  }

  angular.module('windmanagerApp')
    .component('main', {
      templateUrl: 'app/main/main.html',
      controller: MainController
    });

})();
